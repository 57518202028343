<template>
<div>
  <div class="container">
    <router-link tag="header" to="/">
      <img src="~@/assets/img/logo-w-b.png" class="bg-big">
      <img src="~@/assets/img/logo-w-s.png" class="bg-small">
      <h1>魯凱族藥草園知識平台</h1>
    </router-link>

    <article class="mt-4" v-if="data">
      <h2 class="title">詳細資料</h2>
      <div class="row d-flex align-items-center ml-lg-5 mt-5">
        <div class="col-12 col-lg-6">
          <div class="photo-box mx-5 mx-lg-1">
            <div class="title">{{ data.name }}</div>
            <a class="photo-img" data-toggle="modal" data-target="#big-img">
              <img class="zoom" src="~@/assets/img/icon-zoom.svg">
              <img class="photo" :src="data.image" alt=""/>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <ul class="detail-list">
            <li data-title="類別">{{ data.category_cn }}</li>
            <li data-title="英文類別">{{ data.category_en }}</li>
            <li data-title="科名">{{ data.family_cn }}</li>
            <li data-title="英文科名">{{ data.family_en }}</li>
            <li data-title="學名">{{ data.scientific_name }}</li>
            <li data-title="別名">{{ data.another_name }}</li>
            <li data-title="魯凱族語">{{ data.drekay }}</li>
          </ul>
        </div>
      </div>
      <div class="detail-info">
        <div class="subtitle-info"><span>型態</span></div>
        <p>{{ data.info }}</p>
      </div>
      <div class="detail-info">
        <div class="subtitle-info"><span>用途</span></div>
        <p v-html="data.usage"></p>
      </div>
      <div class="d-flex justify-content-center pb-3">
        <router-link tag="button" class="btn btn-grassgreen w-50" to="/">回主頁</router-link>
      </div>
    </article>
  </div>
  <div class="bg-main"></div>
  <div class="bg-mask"></div>
  <div class="bg-photo -detail"></div>

  <BigImg id="big-img" :image="data?.image"/>
</div>
</template>

<script>
import _ from 'lodash'
import BigImg from '@/components/BigImg.vue'
import createError from 'http-errors'

export default {
  name: 'RukaiHerbalDetail',
  components: {
    BigImg,
  },
  data () {
    return {
      data: null,
    }
  },
  computed: {},
  async mounted () {
    await this.init()
    this.getHerbalDetail()
  },
  methods: {
    async init () {
      const { state, dispatch } = this.$store
      try {
        if (state.herbalDatas.length) return
        window.showLoading('載入中', 'Loading...')
        await dispatch('getHerbals')
        this.$swal.close()
      } catch (err) {
        console.error(err)
        await this.$swal.fire({
          icon: 'error',
          text: err.message,
          title: '發生錯誤',
        })
        this.$router.push({ path: '/' })
      }
    },
    async getHerbalDetail () {
      try {
        this.data = _.find(this.$store.state.herbalDatas, { id: this.$route.params.id })
        if (!this.data) throw createError(404, '找不到此筆資料')
      } catch (err) {
        console.error(err)
        await this.$swal.fire({
          icon: 'error',
          text: err.message,
          title: '發生錯誤',
        })
        this.$router.push({ path: '/' })
      }
    },
  },
}
</script>
