<template>
  <div class="modal fade">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="" data-dismiss="modal">
          <div class="button-close-bg"></div>
          <i class="button-close fas fa-times-circle fa-2x"></i>
        </div>
        <div class="modal-body d-flex align-items-center flex-column">
          <img :src="image" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BigImg',
  props: {
    image: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.modal-content {
  border-radius: 5px;
}

.modal-body img {
  width: 100%;
  height: 100%;
  margin: 10px
}

.button-close {
  color: #BCCBAE;
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: 2;
}

.button-close-bg {
  background-color: white;
  border-radius: 99px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: -15px;
  width: 30px;
}
</style>
